import React, { useEffect } from 'react';
import Text from '../../text';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Spinner, Divider } from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../../buttons/dropdown';
import Container from '../../container';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import avatar from '../../../assets/avatar/avatar_1.png'
import { IDriverResourceShort } from '../../../props/drivers';
import DriverVehicleList from '../vehicle/list';
import UpdatePasswordModalArea from '../modals/changePassword';
import UpdateDataModalArea from '../modals/changeData';
import DriverService from '../../../services/drivers';
import moment from 'moment';
import GlobalConfig from '../../../config';
import GeneralFileService from '../../../services/general/file';
import NumberService from '../../../services/general/number';


type DriverDetailsProps = {
  driverId: string;
};

const DriverDetails: React.FC<DriverDetailsProps> = (props) => {
  const navigate = useNavigate();

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [driver, setDriver] = React.useState<IDriverResourceShort | undefined>();
  const [activeModal, setActiveModal] = React.useState<'updatePassword' | undefined>();
  const [activeModalEdit, setActiveModalEdit] = React.useState<'updateData' | undefined>();


  const getActions = (): DropdownButtonSectionProps[] => {
    const actions: DropdownButtonSectionProps[] = [
      {
        key: 'details',
        showDivider: false,
        items: [
          { key: 'editData', text: 'Edit Data', onPress: () => { setActiveModalEdit('updateData') } },
          { key: 'topup', text: 'Top-up saldo', onPress: () => { navigate(`/drivers/${driver?.id}/topup/request`); } },
          { key: 'withdraw', text: 'Tarik saldo', onPress: () => { navigate(`/drivers/${driver?.id}/withdraw`); } },
          { key: 'changePassword', text: 'Ganti password', onPress: () => { setActiveModal('updatePassword') } },
        ]
      },
    ];

    if (driver && !driver.is_id_proof_approved) {
      actions[0].items.unshift({
        key: 'vertification', text: 'Verifikasi Dokumen', onPress: () => {
          navigate(`/drivers/${props.driverId}/vertification`)
        }
      });
    }

    return actions;
  }

  useEffect(() => {
    getDriver()
    return () => {

    }
  }, [activeModalEdit])

  const getDriver = async () => {
    try {
      const _driver = (await DriverService.read(props.driverId)).result;
      setDriver(_driver)
      setLoaded(true)
    } catch (error) {

    }
  }

  return <Container className={styles.container} gap={20} style={{
      backgroundColor: 'white',
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 20,
      // alignItems: 'center',
      // justifyContent: 'center',
      // height: '100vh',
    }}>
    {!loaded ? <Spinner label='Memuat detail ...' /> : null}
    {loaded && driver ? <>
      <Container direction='row' justifyContent='space-between' alignItems='center'>
        <Container direction='row' alignItems='center' gap={10}>
          <img
            src={GeneralFileService.getFileUrl(driver.profile_picture)}
            alt="profile"
            style={{ height: 40, width: 40, borderRadius: 100 }} />
          <Container>
            <Text weight={'medium'}>{driver.first_name}</Text>
            <Text size={'small'}>{Number(driver.gender) === 1 ? 'Laki-laki' : 'Perempuan'}</Text>
          </Container>
        </Container>
        <DropdownButton
          // data={driver}
          sections={getActions()} backdrop='blur' icon={faChevronDown} />
      </Container>
      <Container gap={20}>
        <Container>
          <Text size={'small'} weight={'medium'}>Alamat Email</Text>
          <Text>{driver.email}</Text>
        </Container>
        <Container>
          <Text size={'small'} weight={'medium'}>Nomor Telepon</Text>
          <Text>{driver.phone_with_code}</Text>
        </Container>
        <Container>
          <Text size={'small'} weight={'medium'}>Tanggal Bergabung</Text>
          <Text>{moment(driver.created_at).format('DD/MM/YYYY')}</Text>
        </Container>
        <Container>
          <Text size={'small'} weight={'medium'}>Status Dokumen</Text>
          <Text>{driver?.verification_status?.name}</Text>
        </Container>
      </Container>
      <Divider />
        <Container>
          <Text size={'small'} weight={'medium'}>Sisa Saldo</Text>
          <Text>Rp. {NumberService.getNumberWithSeparator(driver?.wallet || '0')}</Text>
        </Container>
      <Divider />
      <Container gap={20}>
        <Container gap={5}>
          <Text size={'small'} weight={'medium'}>Foto KTP & SIM</Text>
          <img src={GeneralFileService.getFileUrl(driver.id_proof)} alt="stnk" style={{maxWidth: '300px'}} />
        </Container>
        <Container>
          <Text size={'small'} weight={'medium'}>Alamat</Text>
          <Text>{driver?.address || '-'}</Text>
        </Container>
        <Container>
          <Text size={'small'} weight={'medium'}>Tanggal Lahir</Text>
          <Text>{moment(driver.date_of_birth).format("DD/MM/YYYY")}</Text>
        </Container>
      </Container>
      <Divider />
      <Container>
        <Text size='large'>Daftar Kendaraan</Text>
      <DriverVehicleList driverId={props.driverId} />
      </Container>
    </> : null}
    {activeModal && activeModal === 'updatePassword' && driver && <UpdatePasswordModalArea
      onClose={function (refresh?: boolean | undefined): void {
        setActiveModal(undefined)
      }}
      driver={driver} />}
    {activeModalEdit && activeModalEdit === 'updateData' && driver && <UpdateDataModalArea
    onClose={function (refresh?: boolean | undefined): void {
      setActiveModalEdit(undefined)
    }}
    driver={driver} />}
  </Container>
};

export default DriverDetails;