import React, { useEffect } from 'react';
import Text from '../../../text';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Button, Divider, Input, Select, SelectItem, Spinner } from "@nextui-org/react";
import DropdownButton from '../../../buttons/dropdown';
import Container from '../../../container';
import styles from './styles.module.scss';
import { useJsApiLoader } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import GlobalConfig from '../../../../config';
import { IDriverResourceShort } from '../../../../props/drivers';
import DriverService from '../../../../services/drivers';
import TopUpMethods from '../../../../manifests/topUpMethods';
import Footer from '../../../footer';
import NumberService from '../../../../services/general/number';


type DriverRequestTopUpProps = {
    driverId: string;
};

const DriverRequestTopUp: React.FC<DriverRequestTopUpProps> = (props) => {
    const navigate = useNavigate();

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [driver, setDriver] = React.useState<IDriverResourceShort | undefined>();
    const [method, setMethod] = React.useState<any>([]);
    const [amount, setAmount] = React.useState<string>("");
    const [proof, setProof] = React.useState<any>();
    const [phoneNumber, setPhoneNumber] = React.useState<string>("");
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _driver = (await DriverService.read(props.driverId)).result;
        setDriver(_driver);
        setLoaded(true);
    }

    const canSubmit = () => {
        if ((amount || "").trim() === "") {
            return false;
        } else if (!method) {
            return false;
        } else if (method && method.currentKey === 'transfer' && !proof) {
            return false;
        } else if (method && (method.currentKey === 'ID_OVO') && (phoneNumber || "").trim() === "") {
            return false;
        }
        return true;
    }

    const _onSubmit = async () => {
        const paymentMethod = TopUpMethods.find((tu) => tu.key === method.currentKey);
        const sc = getSurcharge();
        setError(undefined);

        if (paymentMethod) {
            setSubmitting(true);

            const fd = new FormData();
            fd.append('driver_id', props.driverId);
            fd.append('amount', amount + "");
            fd.append('payment_method', paymentMethod.method);
            if (paymentMethod.key === "transfer" || paymentMethod.key === "cash") {
                fd.append('proof', proof);
            } else {
                fd.append('channel_mode', paymentMethod.key);
            }

            const topup = (await DriverService.wallet.topup.request(fd)).result;
            navigate(`/drivers/topup/${topup.reference_id}/details`);
        }
    }

    const getSurcharge = () => {
        const paymentMethod = TopUpMethods.find((tu) => tu.key === method.currentKey);
        let surchargeText = "";
        let surchargeAmount = 0;

        if (amount && paymentMethod && (paymentMethod.surchargeAmount > 0 || paymentMethod.surchargePercentage > 0)) {
            if (paymentMethod.surchargeAmount > 0 && paymentMethod.surchargePercentage > 0) {
                surchargeAmount += paymentMethod.surchargeAmount;
                surchargeAmount += Number(amount) * paymentMethod.surchargePercentage / 100;

                surchargeText = `Rp. ${NumberService.getNumberWithSeparator(paymentMethod.surchargeAmount)} + ${NumberService.getNumberWithSeparator(paymentMethod.surchargePercentage)}% ()`;
            } else if (paymentMethod.surchargeAmount > 0) {
                surchargeAmount += paymentMethod.surchargeAmount;
                surchargeText = `Rp. ${NumberService.getNumberWithSeparator(paymentMethod.surchargeAmount)}`;
            } else if (paymentMethod.surchargePercentage > 0) {
                surchargeAmount += Number(amount) * paymentMethod.surchargePercentage / 100;
                surchargeText = `${NumberService.getNumberWithSeparator(paymentMethod.surchargePercentage)}% (Rp. ${NumberService.getNumberWithSeparator(surchargeAmount)})`;
            }
        }
        return {surchargeText, surchargeAmount};
    }

    const renderProofImage = () => {
        if (proof) {
            const src = URL.createObjectURL(proof);
            return <img src={src} style={{ maxWidth: 200 }} />
        }
        return <></>;
    }

    const renderFinalAmount = () => {
        const sc = getSurcharge();
        if (sc.surchargeAmount > 0 && sc.surchargeText !== "") {
            return <Container>
                <Text style={{fontStyle: 'italic'}}>Pembayaran menggunakan {method.text} dikenakan surcharge sebesar {sc.surchargeText}</Text>
                <Text style={{fontStyle: 'italic'}}>Nominal final pembayaran sebesar Rp. {NumberService.getNumberWithSeparator(Number(amount) + sc.surchargeAmount)}</Text>
            </Container>;
        }
        return <></>;
    }

    return <Container className={styles.container} style={{
            backgroundColor: 'white',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 20,
            // alignItems: 'center',
            // justifyContent: 'center',
            // height: '80vh',
        }} gap={20}>
        {!loaded ? <Spinner label='Memuat detail ...' /> : null}
        {loaded && driver ? <>
            <Container direction='row' justifyContent='space-between' alignItems='center'>
                <Text size={'xl'} weight='bold'>Top-up Saldo Driver</Text>
            </Container>
            <Container gap={20}>
                {error ? <Text size={'small'} style={{color: '#a20000'}}>{error}</Text> : null}
                <Container gap={3}>
                    <Text size={'small'} weight='semibold'>Driver</Text>
                    <Text size={'small'}>{driver.first_name} {driver.last_name || ""}</Text>
                </Container>
                <Container gap={3}>
                    <Text size={'small'} weight='semibold'>Saldo Saat Ini</Text>
                    <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(driver.wallet)}</Text>
                </Container>
                <Divider />
                <Container gap={3}>
                    <Text size={'small'} weight='semibold'>Metode Pembayaran</Text>
                    <Select fullWidth={true} selectionMode={'single'} className="max-w-xs" selectedKeys={method} onSelectionChange={(value: any) => setMethod(value)}>
                        {TopUpMethods.map((tu) => {
                            return <SelectItem key={tu.key} value={tu.key}>{tu.text}</SelectItem>;
                        })}
                    </Select>
                </Container>
                {method.currentKey === 'transfer' ? <Container style={{ gap: 4 }}>
                    <Text size={'small'} weight='medium'>Bukti Pembayaran</Text>
                    <input type="file"
                        onChange={(evt: any) => {
                            const value = evt?.target?.files ? evt?.target?.files[0] : undefined;
                            setProof(value);
                        }}
                        accept="image/*" />
                    {renderProofImage()}
                </Container> : null}
                {method.currentKey === 'ID_OVO' || method.currentKey === 'ID_SHOPPEEPAY' ? <>
                    <Input label={'Nomor Telepon'} 
                        labelPlacement='outside'
                        value={phoneNumber} 
                        inputMode='numeric'
                        placeholder='Nomor telepon yang tersambung ke e-wallet ...'
                        onValueChange={setPhoneNumber}
                        startContent={<Text size={'small'}>+61</Text>} />
                </> : null}
                <Divider />
                <Container>
                    <Input label={'Nominal'} 
                        labelPlacement='outside'
                        value={amount} 
                        inputMode='numeric'
                        placeholder='Nominal topup'
                        onValueChange={setAmount}
                        startContent={<Text size={'small'}>Rp. </Text>} />
                    {renderFinalAmount()}
                </Container>
                <Container>
                    <Button color="primary" onPress={_onSubmit}  isDisabled= {!canSubmit()} isLoading={submitting} style={{ width: '100%' }}>
                        Top-up
                    </Button>
                </Container>
            </Container>
            {/* <Footer buttons={[
                {
                    key: 'topup',
                    color: 'primary',
                    children: "Top-up",
                    isLoading: submitting,
                    isDisabled: !canSubmit(),
                    onPress: _onSubmit
                }
            ]} /> */}
        </> : null}
    </Container>
};

export default DriverRequestTopUp;