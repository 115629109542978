import React, { useState } from 'react';
import Container from '../container';
import Text from '../text';
import styles from './styles.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCar, faCarSide, faDollar, faInfo, faInfoCircle, faLocationXmark, faMoneyBillWave, faUser, faUserGear, faXmark, faAnalytics } from '@fortawesome/pro-light-svg-icons';
import { NavLink, useNavigate, useNavigation } from 'react-router-dom';
import logo from '../../assets/logo/logo.png'


type TypeNavbarCopmponentProps = {
  children?: React.ReactNode;
  className?: string
};

const NavbarCopmponent: React.FC<TypeNavbarCopmponentProps> = (props) => {
  const navigate = useNavigate();
  const links = [
    {
      title: 'Dashboard',
      icon: faAnalytics,
      items: [
        {
          text: 'Analytics',
          onClick: () => {
            setIsOpen(false)
            navigate('/analytics');
          },
        },
      ]
    },
    {
      title: 'Admin',
      icon: faUserGear,
      items: [
        {
          text: 'Daftar Admin',
          onClick: () => {
            setIsOpen(false)
            navigate('/admins');
          },
        },
      ]
    },
    {
      title: 'Pelanggan',
      icon: faUser,
      items: [
        {
          text: 'Absensi (NEW)',
          onClick: () => {
            setIsOpen(false)
            navigate('/customers/absensi');
          },
        },
        {
          text: 'Daftar Pelanggan',
          onClick: () => {
            setIsOpen(false)
            navigate('/customers');
          },
        },
        {
          text: 'Export Pelanggan',
          onClick: () => {
            setIsOpen(false)
            navigate('/customers/list_export');
          },
        },
        /*{
          text: 'Tambah Pelanggan',
          onClick: () => {
            setIsOpen(false)
            navigate('/customers');
          },
        }*/
      ]
    },
    {
      title: 'Driver',
      icon: faCarSide,
      items: [
        {
          text: 'Daftar Driver',
          onClick: () => {
            setIsOpen(false)
            navigate('/drivers');
          },
        },
        /*{
          text: 'Tambah Driver',
          onClick: () => {
            setIsOpen(false)
            navigate('/drivers');
          },
        }*/
      ]
    },
    {
      title: 'Pesanan',
      icon: faLocationXmark,
      items: [
        /*{
          text: 'Buat Pesanan',
          onClick: () => {
            setIsOpen(false)
            navigate('/orders/create');
          },
        },*/
        {
          text: 'Daftar Pesanan',
          onClick: () => {
            setIsOpen(false)
            navigate('/orders');
          },
        }
      ]
    },
    {
      title: 'Keuangan',
      icon: faMoneyBillWave,
      items: [
        {
          text: 'History',
          onClick: () => {
            setIsOpen(false)
            navigate('/drivers/wallet_histories');
          },
        },
      ]
    },
    {
      title: 'Konfigurasi',
      icon: faInfoCircle,
      items: [
        {
          text: 'Daftar Zona',
          onClick: () => {
            setIsOpen(false)
            navigate('/zones');
          },
        },
        {
          text: 'Daftar Area',
          onClick: () => {
            setIsOpen(false)
            navigate('/areas');
          },
        }
      ]
    },
  ]

  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Container direction='column' className={`${styles.container} ${props.className}`}>
      <Container
        direction={'row'}
        justifyContent='space-between'
        alignItems='center'
        className={styles.navbar}
      >
        <img src={logo} alt="logo" style={{maxWidth: '50px'}} />
        <NavLink to={"/"}><Text size={'2xl'} weight='bold'>My Drivers</Text></NavLink>
        <FontAwesomeIcon icon={isOpen ? faXmark : faBars} size='2xl' className={styles.barsIcon} onClick={() => setIsOpen(!isOpen)} />
      </Container>
      {isOpen &&
        <Container className={styles.items} gap={20}>
          {links.map((link, i) => (
            <Container key={i} direction='row' gap={10}>
              <FontAwesomeIcon icon={link.icon} size='sm' style={{ paddingTop: 4 }} />
              <Container gap={10}>
                <Text size={'base'} weight='bold'>{link.title}</Text>
                {link.items.map((item, ix) => (
                  <Text
                    style={{
                      cursor: 'pointer',
                      userSelect: 'none'
                    }}
                    key={ix}
                    size={'sm'}
                    weight='medium'
                    onClick={item.onClick}
                  >{item.text}</Text>
                ))}
              </Container>
            </Container>
          ))}
        </Container>
      }
    </Container >
  )
};

export default NavbarCopmponent;